<template>
  <div class="py-5 px-2">
    <div class="card">
      <article class="card-body">
        <h4 class="card-title text-center mb-4 mt-1">Forgot Password?</h4>
        <hr />
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(save)">
          <b-alert variant="success" show v-if="feedback">{{feedback}}</b-alert>
          <p class="mb-4">
            Please provide a valid email address below. An email containing a link to reset your password will be sent to the address if it matches our record.
          </p>
          <div class="form-group row no-gutters">
            <div class="col-sm-9 pr-sm-2">
              <ValidationProvider name="Email" rules="required|email" mode="eager" v-slot="{ errors }">
                <label for="email" class="sr-only">Email Address</label>
                <input name="email" v-model="email" class="form-control" placeholder="Email Address" type="email" />
                <span  class="text-danger form-text">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-sm-3">
              <button class="btn btn-primary" type="submit"><b-spinner small v-if="loading" /> Send Link</button>
            </div>
          </div>
        </form>
        </ValidationObserver>
      </article>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import firebase from '@/store/modules/auth/firebase.config';
import '@/util/validation';

export default {
  name: 'ForgotPassword',
  components: {
    ValidationProvider, ValidationObserver,
  },
  data() {
    return {
      email: '',
      loading: false,
      feedback: '',
    };
  },
  methods: {
    save() {
      this.loading = true;
      firebase.auth().sendPasswordResetEmail(this.email)
        .then(() => {
          this.feedback = 'An email has been sent to your registered email address.';
        })
        .catch(() => {
          this.$toast.error('Failed to send password reset link. Please, try again later.');
        })
        .finally(() => {
          this.loading = false;
          this.email = '';
          this.$refs.form.reset();
        });
    },
  },
};
</script>
